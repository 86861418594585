<div class="uk-section uk-section-default">
    <div class="uk-container">
        <img class="uk-align-center" src="assets/images/logo.png" alt="logo" width="80" height="80">
        <h2 class="uk-text-bold uk-text-secondary uk-text-center uk-font-ubuntu">Team</h2>
        <h2 class="uk-text-bold uk-text-secondary uk-text-center uk-font-ubuntu">Trustees</h2>
        <div class="uk-child-width-1-3@m uk-child-width-1-2@s" uk-grid>
            <div class="">
                <img class="uk-margin-small-bottom" src="assets/images/profile/anand.jpg" alt="anand_profile_img" width="300" height="300">
                <h3 class="uk-text-secondary uk-text-bolder uk-margin-remove">Anand S.K</h3>
                <p class="uk-text-secondary uk-text-bold uk-margin-remove">Chairman</p>
            </div>
            <div class="">
                <img class="uk-margin-small-bottom" src="assets/images/profile/ganesh.jpg" alt="ganesh_profile_img" width="300" height="300">
                <h3 class="uk-text-secondary uk-text-bolder uk-margin-remove">Ganesh Jagadheeshan</h3>
                <p class="uk-text-secondary uk-text-bold uk-margin-remove">Vice Chairman</p>
            </div>
            <div class="">
                <img class="uk-margin-small-bottom" src="assets/images/profile/ram.jpg" alt="ram_profile_img" width="300" height="300">
                <h3 class="uk-text-secondary uk-text-bolder uk-margin-remove">Ramprakash Jagadheeshan</h3>
                <p class="uk-text-secondary uk-text-bold uk-margin-remove">Secretary</p>
            </div>
            <div class="">
                <img class="uk-margin-small-bottom" src="assets/images/profile/madhu.jpg" alt="madhu_profile_img" width="300" height="300">
                <h3 class="uk-text-secondary uk-text-bolder uk-margin-remove">Madhu Damodaran</h3>
                <p class="uk-text-secondary uk-text-bold uk-margin-remove">Treasurer</p>
            </div>
            <div class="">
                <img class="uk-margin-small-bottom" src="assets/images/profile/njain.jpg" alt="nirmal_profile_img" width="300" height="300">
                <h3 class="uk-text-secondary uk-text-bolder uk-margin-remove">Nirmal Jain</h3>
                <p class="uk-text-secondary uk-text-bold uk-margin-remove">Trustee</p>
            </div>
            <div class="">
                <img class="uk-margin-small-bottom" src="assets/images/profile/adhi.jpg" alt="adhi_profile_img" width="300" height="300">
                <h3 class="uk-text-secondary uk-text-bolder uk-margin-remove">Adhithya</h3>
                <p class="uk-text-secondary uk-text-bold uk-margin-remove">Trustee</p>
            </div>
        </div>
        <hr class="uk-margin-large-top uk-divider-icon" />
        <div class="uk-margin-large-top">
            <h2 class="uk-font-ubuntu uk-text-bold uk-text-secondary  uk-text-center">Project Coordinator</h2>
            <div class="uk-card uk-card-default uk-grid-collapse uk-margin" uk-grid>
                <div class="uk-card-media-left uk-cover-container uk-width-1-3@s">
                    <img src="assets/images/profile/Rameshan.jpg" alt="" uk-cover>
                    <canvas width="600" height="400"></canvas>
                </div>
                <div class="uk-width-2-3@s">
                    <div class="uk-card-body">
                        <h3 class="uk-card-title uk-text-bold">
                            R.RAMESHAN</h3>
                        <p>He graduated in B.A and M.Ed. He has received the Dr. Radhakrishnan Anadee ‘Best Teacher’ award from the Government of Tamil Nadu. He is a member of the Tamil Nadu Science Forum and has written Govt. Science textbooks for classes
                            2 & 3 Out of personal interest, he takes astronomy classes for students and teaches them simple magic tricks post retirement to improve their extra- curriculars. He holds the position of advisor in Coimbatore Arom Charitable
                            Trust and coordinator in Amoha Global Trust. We are glad to have such a valuable addition in our team.
                        </p>
                    </div>
                </div>
            </div>
            <div class="uk-card uk-card-default uk-grid-collapse uk-margin" uk-grid>
                <div class="uk-card-media-left uk-cover-container uk-width-1-3@s">
                    <img src="assets/images/profile/Pragadeeswaran.jpeg" alt="" uk-cover>
                    <canvas width="600" height="400"></canvas>
                </div>
                <div class="uk-width-2-3@s">
                    <div class="uk-card-body">
                        <h3 class="uk-card-title uk-text-bold">
                            Mr.K. Pragadeeswaran</h3>
                        <p>Graduated in Mathematics in 1967.Before qualifying for the Teacher Training Degree, he rendered his service in two aided high schools in Tirupur (1967-1969) and taught Mathematics and English to students.He studied B.T.in Ramakrishna
                            mission Vidhyalaya Periyanaickenpalayam and qualified as a trained teacher. He entered Govt.service in 1970 and worked as a maths teacher in Cheyur. He was selected by T.N.P.S.C in 1973 to regularise his services in the combined
                            cadre of school inspector and School assistant (maths). In 1978 he got transferred and was posted as the Deputy Inspector of Schools.The principle duty was to inspect primary and middle schools and to assess the standard of
                            children studying in the area.Later, he served as an Assistant edl.Officer in different edl Ranges.The duty included the inspection of schools and managerial service of teachers.In 2000, he got promoted as the headmaster of
                            a high school and had the chance of working in Sarva Siksha Abhiyan Scheme when it was introduced. Retired as the Headmaster of a Higher secondary school in 2005. He served as an Educational Advisor to a High school and is
                            blessed with the acquaintance of philanthropists who are responsible to provide assistance in ensuring quality education to rural children. We are happy to have his support in finding relevant projects.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>