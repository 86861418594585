<div class="uk-section-default">
    <div class="uk-section  uk-section-large uk-background-cover" style="background-image: url(assets/images/about-header.jpg);top: 0px;">
        <div class="uk-container">

        </div>
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove">
    <div class="uk-container">
        <div class="uk-card uk-card-default uk-card-body uk-border-rounded">
            <h2 class="uk-text-bolder uk-text-secondary uk-font-ubuntu">The thought process behind our initiatives</h2>
            <hr class="uk-divider-icon">
            <ul class="uk-list uk-font-ubuntu uk-text-emphasis">
                <li>
                    ✓ Amoha Global Trust, in its quest to achieve the stated mission, has initiated education support projects for students studying in schools located in remote villages of Tamilnadu.
                </li>
                <li>
                    ✓ Students studying 10th, 11th and 12th grades in privileged private schools have the option of hiring tuition teachers to make the grade, and move on to college education smoothly and successfully. But students pursuing these board exams in remote schools
                    are many times left with no teachers for some courses, no practical labs, leave alone the luxury of private tuitions.
                </li>
                <li>
                    ✓ This situation leads to high dropouts at the school level, and many of them never stepping into a college to obtain a degree. This in turn leads to their unemployability in today’s economy that is increasingly service industry driven.
                </li>
                <li>
                    ✓ Unemployability leads to disruption in families and the society at large. The unemployed youth have no recourse other than being a burden to the economy and in the worst case scenario, can lead lo a life of crime, alcoholism, drugs and misery.
                </li>
                <li>
                    ✓ Amoha wants to do its bit to reduce this dropout rate, so that the youth get a degree and hopefully a successful career, to be ultimately self-sufficient. Our projects, as one can view in this website, are all aimed at actualising this thought process.
                </li>
            </ul>
        </div>
    </div>
</div>