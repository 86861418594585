import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomePageComponent } from './home-page/home-page.component';
import { RouterModule } from '@angular/router';
import { CausePageComponent } from './cause-page/cause-page.component';
import { TeamPageComponent } from './team-page/team-page.component';
import { ProjectpageComponent } from './projectpage/projectpage.component';
import { ContributePageComponent } from './contribute-page/contribute-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { FooterComponent } from './footer/footer.component';
import { AgmCoreModule } from '@agm/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

const route = [
  { path: '', component: HomePageComponent },
  { path: 'cause', component: CausePageComponent },
  { path: 'team', component: TeamPageComponent },
  { path: 'projects', component: ProjectpageComponent },
  { path: 'contribute', component: ContributePageComponent },
  { path: 'contactus', component: ContactPageComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomePageComponent,
    PageNotFoundComponent,
    CausePageComponent,
    TeamPageComponent,
    ProjectpageComponent,
    ContributePageComponent,
    ContactPageComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forChild(route),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA0WMVGQGv5c60JIiG1JJxqiIfFLARch0g',
    }),
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
