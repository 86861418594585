<div class="uk-section uk-section-secondary">
    <div class="uk-container">
        <div class="uk-grid-small uk-child-width-1-3@s" uk-grid>
            <div class="">
                <img src="assets/images/logo.png" width="120" height="120" alt="logo">
            </div>
            <div class="">
                <h3>Address</h3>
                <p class="uk-margin-remove">Amoha Global Trust,</p>
                <p class="uk-margin-remove">Mayflower Valencia, 6<sub>th</sub> floor,</p>
                <p class="uk-margin-remove">336 Avinashi Road, Coimbatore - 641004</p>
            </div>
            <div class="">
                <h3>Contact Us</h3>
                <p class="uk-margin-remove"><span uk-icon="icon: phone"></span> +0422 4225235, 3511566</p>
                <p class="uk-margin-remove"><span uk-icon="icon: mail"></span> mail@anandsk.com</p>
            </div>
        </div>
    </div>
</div>