<div class="uk-section uk-section-default">
    <div class="uk-container">
        <!-- <agm-map [latitude]="lat" [longitude]="lng" [mapTypeId]="mapType">
        </agm-map>
         -->
        <div class="uk-card uk-card-default uk-card-body uk-border-rounded">
            <div class="uk-child-width-1-2@s" uk-grid>
                <div class="">

                    <form (submit)="sendMail()">
                        <fieldset class="uk-fieldset">

                            <legend class="uk-legend uk-text-bold">Send us a message</legend>

                            <div class="uk-margin">
                                <input class="uk-input form-control" type="text" name="fullName" required placeholder="Your Name" [(ngModel)]="fullName" #name="ngModel">
                                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                                    <div *ngIf="name.errors?.required">
                                        Full Name is required.
                                    </div>
                                </div>
                            </div>

                            <div class="uk-margin">
                                <input class="uk-input" type="email" name="email" placeholder="Email Address" [(ngModel)]="email" #name="ngModel" required>
                                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                                    <div *ngIf="name.errors?.required">
                                        Email is required.
                                    </div>
                                </div>
                            </div>

                            <div class="uk-margin">
                                <input class="uk-input" type="number" name="mobile" minlength="10" maxlength="10" placeholder="Mobile" [(ngModel)]="mobile" #name="ngModel" required>
                                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                                    <div *ngIf="name.errors?.required">
                                        Mobile is required.
                                    </div>
                                    <div *ngIf="name.errors?.minlength || name.errors?.maxlength">
                                        Mobile must be 10 numbers.
                                    </div>
                                </div>
                            </div>

                            <div class="uk-margin">
                                <textarea class="uk-textarea" rows="5" name="message" placeholder="Your Message" [(ngModel)]="message" #name="ngModel" required></textarea>
                                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                                    <div *ngIf="name.errors?.required">
                                        Message is required.
                                    </div>
                                </div>
                            </div>

                        </fieldset>

                        <button class="uk-button uk-button-primary uk-border-rounded uk-align-center">Send</button>
                    </form>
                </div>
                <div class="uk-margin-auto-vertical uk-text-center">
                    <h4 class="uk-text-bold">Address</h4>
                    <p class="uk-margin-remove">Amoha Global Trust,</p>
                    <p class="uk-margin-remove">Mayflower Valencia, 6<sub>th</sub> floor,</p>
                    <p class="uk-margin-remove">336 Avinashi Road, Coimbatore - 641004</p>
                    <p class="uk-margin-remove">Tel: +0422 4225235, 3511566</p>
                </div>
            </div>
        </div>
    </div>
</div>