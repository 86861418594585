import { HttpClient } from '@angular/common/http';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import { Component, OnInit, ViewChild } from '@angular/core';

declare var UIkit: any;

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit {
  lat: 11.006096;
  lng: 76.975165;
  mapType = 'satellite';
  fullName: string;
  mobile: number;
  email: string;
  message: string;
  constructor(public http: HttpClient) {}

  ngOnInit(): void {}

  sendMail() {
    let params = {};
    params['name'] = this.fullName || null;
    params['mobile'] = this.mobile || null;
    params['email'] = this.email || null;
    params['message'] = this.message || null;
    const promises = new Promise((resolve, reject) => {
      // README: Change the URL when testing the form in localhost.
      this.http.post('https://amohaglobal.org/sendEmail', params).subscribe((data) => {
        resolve(data);
        console.log(`Mail sending response -- ${data}`);
      });
    });

    promises
      .then((res) => {
        console.log(`response from server -- ${res}`);
        UIkit.notification('Mail has sent Successfully!', {
          pos: 'bottom-center',
        });
      })
      .finally(() => {
        this.fullName = '';
        this.mobile = null;
        this.email = '';
        this.message = '';
      });
  }
}
