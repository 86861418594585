<div class="uk-position-relative uk-visible-toggle uk-light uk-margin-remove" tabindex="-1" uk-slideshow="autoplay: true">

    <ul class="uk-slideshow-items">
        <li>
            <img src="assets/images/05-full.jpg" alt="" uk-cover>
        </li>
        <li>
            <img src="assets/images/about-header.jpg" alt="" uk-cover>
        </li>
        <li>
            <img src="assets/images/edu_infra_2.jpg" alt="" uk-cover>
        </li>
        <li>
            <img src="assets/images/edu_infra_1.jpg" alt="" uk-cover>
        </li>
    </ul>

    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>

</div>

<!-- Our mission section -->
<div class="uk-section uk-section-muted">
    <div class="uk-container">
        <div class="uk-card uk-card-body uk-card-default uk-border-rounded">
            <div class="" uk-grid>
                <div class="uk-width-1-3@s uk-margin-auto-vertical">
                    <img src="assets/images/eductional_card.svg" alt="educational_img" width="250" height="250">
                </div>
                <div class="uk-width-2-3@s">
                    <h2 class="uk-text-bold uk-text-secondary uk-font-ubuntu">Our mission and why we exist</h2>
                    <!-- <div class="uk-divider-small uk-border-rounded ">
                    </div> -->
                    <hr class="uk-divider-icon">
                    <ul class="uk-list uk-text-emphasis uk-font-ubuntu">
                        <li>❖ To help improve the lives of the ignorant by inculcating the right knowledge and skills in them, besides making them aware of their duties, responsibilities and privileges as responsible citizens of the country</li>
                        <li>
                            ❖ To facilitate and provide right education to the deserving and needy students and sections of the society
                        </li>
                        <li>
                            ❖ To improve employability of fresh graduates and others by imparting the right skills in them
                        </li>
                    </ul>
                    <div class=" uk-margin-large-top">
                        <a routerLink="cause">
                            <button class="uk-button uk-button-secondary uk-text-capitalize uk-border-rounded">
                                Learn More About Amoha
                            </button>
                        </a>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>