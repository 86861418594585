<div class="uk-section-default">
  <div
    class="uk-section uk-section-large uk-background-cover"
    style="background-image: url(assets/images/about-header.jpg); top: 0px"
  >
    <div class="uk-container"></div>
  </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove">
  <div class="uk-container">
    <div class="uk-card uk-card-default uk-card-body uk-border-rounded">
      <h2 class="uk-text-bold uk-text-secondary uk-font-ubuntu">Projects</h2>
      <div class="uk-margin-large-top">
        <div uk-grid>
          <div class="uk-width-auto@m">
            <ul
              class="uk-tab-left"
              uk-tab="connect: #component-tab-left; animation: uk-animation-fade"
            >
              <li><a href="#">2016 - 2017</a></li>
              <li class="uk-margin-top"><a href="#">2017 - 2018</a></li>
              <li class="uk-margin-top"><a href="#">2018 - 2019</a></li>
              <li class="uk-margin-top"><a href="#">2019 - 2020</a></li>
              <li class="uk-margin-top"><a href="#">2020 - 2021</a></li>
              <li class="uk-margin-top"><a href="#">2021 - 2022</a></li>
              <li class="uk-margin-top"><a href="#">2022 - 2023</a></li>
              <li class="uk-margin-top"><a href="#">2023 - 2024</a></li>
            </ul>
          </div>
          <div class="uk-width-expand@m">
            <ul id="component-tab-left" class="uk-switcher">
              <li>
                <ul class="uk-subnav uk-subnav-pill" uk-switcher>
                  <li><a href="#">HAND HOLDING TO END DROPOUTS</a></li>
                  <li><a href="#">DIRECT EDUCATION SUPPORT</a></li>
                </ul>

                <ul class="uk-switcher uk-margin">
                  <li>
                    <P>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their school education and
                      not being able to secure a college education.</P
                    >
                    <P>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their Amoha aims to hand
                      hold and lift students from such schools and enable them
                      to move ahead into the next stage of their life and career
                      by appointing teachers and bridging the gap.
                    </P>
                  </li>
                  <li>
                    A financially disadvantaged 3rd year BE CSE student of PSG
                    institute if technology and applied research was supported
                    by Amoha to complete her education and take up a career.
                  </li>
                </ul>
              </li>

              <li>
                <ul class="uk-subnav uk-subnav-pill" uk-switcher>
                  <li><a href="#">HAND HOLDING TO END DROPOUTS</a></li>
                  <li><a href="#">PRACTICAL EDUCATION SUPPORT</a></li>
                  <li><a href="#">EDUCATION INFRASTRUCTURE SUPPORT</a></li>
                </ul>

                <ul class="uk-switcher uk-margin">
                  <li>
                    <p>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their school education and
                      not being able to secure a college education.
                    </p>
                    <p>
                      ◆ Amoha aims to hand hold and lift students from such
                      schools and enable them to move ahead into the next stage
                      of their life and career by appointing teachers and
                      bridging the gap.
                    </p>
                    <div class="uk-margin-top uk-overflow-auto">
                      <table class="uk-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src="assets/images/children.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/teacher.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/classroom.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/subject.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Children Served</td>
                            <td>Teachers Appointed</td>
                            <td>Classes Supported</td>
                            <td>Subjects Taught</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Valparai</td>
                            <td>225</td>
                            <td>2</td>
                            <td>3</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Sholayar Dam
                            </td>
                            <td>141</td>
                            <td>2</td>
                            <td>4</td>
                            <td>3</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <li>
                    <p>
                      ◆ A few schools located in non-urban areas of the state
                      lack science labs and the equipment to teach children
                      practical science that is a part of their 10th, 11th and
                      12th grade education.
                    </p>
                    <p>
                      ◆ Amoha has provided the much needed lab equipment,
                      chemicals, charts, etc., to equip physics chemistry botany
                      and zoology labs of these schools. This has enormously
                      helped the students in securing better grades in the board
                      exams paving the way to their college education.
                    </p>
                    <div class="uk-margin-top uk-overflow-auto">
                      <table class="uk-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src="assets/images/children.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/teacher.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/classroom.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/subject.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Children Served</td>
                            <td>Teachers Appointed</td>
                            <td>Classes Supported</td>
                            <td>Subjects Taught</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Valparai</td>
                            <td>168</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Sholayar Dam
                            </td>
                            <td>267</td>
                            <td>2</td>
                            <td>5</td>
                            <td>4</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Pannimadai High School</td>
                            <td>179</td>
                            <td>1</td>
                            <td>2</td>
                            <td>1</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="uk-overflow-auto uk-margin-top">
                      <table class="uk-table uk-table-divider">
                        <thead>
                          <tr style="height: 50px">
                            <th rowspan="2">School Name</th>
                            <th rowspan="2">Location</th>
                            <th rowspan="2">Lab Type</th>
                            <th rowspan="2">Amount Required</th>
                            <th colspan="3">Beneficiaries</th>
                            <th rowspan="2">Total</th>
                            <th rowspan="2">Total Amount to the School</th>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">10<sup>th</sup></td>
                            <td valign="bottom">+1</td>
                            <td valign="bottom">+2</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="height: 50px">
                            <td valign="bottom" rowspan="2">
                              Govt. Hr. Sec. School (Girls)
                            </td>
                            <td valign="bottom" rowspan="2">Valparai</td>
                            <td valign="bottom">Chemistry</td>
                            <td valign="bottom">23,258</td>
                            <td valign="bottom" rowspan="2">95</td>
                            <td valign="bottom" rowspan="2">123</td>
                            <td valign="bottom" rowspan="2">140</td>
                            <td valign="bottom" rowspan="2">358</td>
                            <td valign="bottom" rowspan="2">28,008</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Physics</td>
                            <td valign="bottom">4,760</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom" rowspan="3">
                              Govt. Hr. Sec. School
                            </td>
                            <td valign="bottom" rowspan="3">Sholaiyar Dam</td>
                            <td valign="bottom">Chemistry</td>
                            <td valign="bottom">9,157</td>
                            <td valign="bottom" rowspan="3">50</td>
                            <td valign="bottom" rowspan="3">39</td>
                            <td valign="bottom" rowspan="3">61</td>
                            <td valign="bottom" rowspan="3">150</td>
                            <td valign="bottom" rowspan="3">33,643<br /></td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Biology</td>
                            <td valign="bottom">11,819</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Physics</td>
                            <td valign="bottom">12,667</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Govt. Hr. Sec. School</td>
                            <td valign="bottom">Diwan Sabho Podur, Pollachi</td>
                            <td valign="bottom">Chemistry &amp; Physics</td>
                            <td valign="bottom">17,101</td>
                            <td valign="bottom">130</td>
                            <td valign="bottom">124</td>
                            <td valign="bottom">110</td>
                            <td valign="bottom">364</td>
                            <td valign="bottom">17,101</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">
                              Govt. Hr. Sec. School (Boys)
                            </td>
                            <td valign="bottom">Thondamuthur</td>
                            <td valign="bottom">Chemistry</td>
                            <td valign="bottom">14,442</td>
                            <td valign="bottom" rowspan="4">110</td>
                            <td valign="bottom" rowspan="4">180</td>
                            <td valign="bottom" rowspan="4">170</td>
                            <td valign="bottom" rowspan="4">640</td>
                            <td valign="bottom" rowspan="4">64,153</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom"></td>
                            <td valign="bottom"></td>
                            <td valign="bottom">Physics</td>
                            <td valign="bottom">5,530</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom"></td>
                            <td valign="bottom"></td>
                            <td valign="bottom">Zoology</td>
                            <td valign="bottom">3,8857</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom"></td>
                            <td valign="bottom"></td>
                            <td valign="bottom">Botany</td>
                            <td valign="bottom">5,324</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Total</td>
                            <td valign="bottom"></td>
                            <td valign="bottom"></td>
                            <td valign="bottom"></td>
                            <td valign="bottom"></td>
                            <td valign="bottom"></td>
                            <td valign="bottom"></td>
                            <td valign="bottom">1,332</td>
                            <td valign="bottom">1,42,905</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="uk-margin-top">
                      <div
                        class="uk-child-width-1-3@m uk-child-width-1-2@S"
                        uk-grid
                        uk-lightbox="animation: slide"
                      >
                        <div>
                          <a
                            class="uk-inline"
                            href="assets/images/edu_sup_2.jpg"
                            data-caption="Science Lab"
                          >
                            <img
                              src="assets/images/edu_sup_2.jpg"
                              width="1800"
                              height="1200"
                              alt=""
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            class="uk-inline"
                            href="assets/images/edu_sup_1.jpg"
                            data-caption="Lab 1"
                          >
                            <img
                              src="assets/images/edu_sup_1.jpg"
                              width="1800"
                              height="1200"
                              alt=""
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            class="uk-inline"
                            href="assets/images/edu_sup_3.jpg"
                            data-caption="Lab 2"
                          >
                            <img
                              src="assets/images/edu_sup_3.jpg"
                              width="1800"
                              height="1200"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <p>
                      ◆ The Valparai Girls High School, was functioning in a
                      dilapidated school building which was demotivating the
                      students. Amoha helped renovate the school building by
                      painting and patchworking the existing building, thus
                      making it pleasing for the students to attend the school.
                      The trust has spent RS 1, 50, 000 for the renovation.
                    </p>
                    <div class="uk-margin-top">
                      <div
                        class="uk-child-width-1-4@m uk-child-width-1-2@S"
                        uk-grid
                        uk-lightbox="animation: slide"
                      >
                        <div>
                          <a
                            class="uk-inline"
                            href="assets/images/edu_infra_1.jpg"
                            data-caption="Science Lab"
                          >
                            <img
                              src="assets/images/edu_infra_1.jpg"
                              width="1800"
                              height="1200"
                              alt=""
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            class="uk-inline"
                            href="assets/images/edu_infra_2.jpg"
                            data-caption="Lab 1"
                          >
                            <img
                              src="assets/images/edu_infra_2.jpg"
                              width="1800"
                              height="1200"
                              alt=""
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            class="uk-inline"
                            href="assets/images/edu_infra_4.jpg"
                            data-caption="Lab 2"
                          >
                            <img
                              src="assets/images/edu_infra_4.jpg"
                              width="1800"
                              height="1200"
                              alt=""
                            />
                          </a>
                        </div>
                        <div>
                          <a
                            class="uk-inline"
                            href="assets/images/edu_infra_3.jpg"
                            data-caption="Lab 2"
                          >
                            <img
                              src="assets/images/edu_infra_3.jpg"
                              width="1800"
                              height="1200"
                              alt=""
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <ul class="uk-subnav uk-subnav-pill" uk-switcher>
                  <li><a href="#">HAND HOLDING TO END DROPOUTS</a></li>
                  <li><a href="#">DIRECT EDUCATION SUPPORT</a></li>
                </ul>

                <ul class="uk-switcher uk-margin">
                  <li>
                    <p>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their school education and
                      not being able to secure a college education.
                    </p>
                    <p>
                      ◆ Amoha aims to hand hold and lift students from such
                      schools and enable them to move ahead into the next stage
                      of their life and career by appointing teachers and
                      bridging the gap.
                    </p>
                    <div class="uk-margin-top uk-overflow-auto">
                      <table class="uk-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src="assets/images/children.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/teacher.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/classroom.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/subject.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Children Served</td>
                            <td>Teachers Appointed</td>
                            <td>Classes Supported</td>
                            <td>Subjects Taught</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Valparai</td>
                            <td>386</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Sholayar Dam
                            </td>
                            <td>117</td>
                            <td>2</td>
                            <td>2</td>
                            <td>2</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <li>
                    <P>
                      ◆ The trust has supported 12 students studying in PSG I
                      tech college to complete their education. The trust has
                      spent RS 1, 20, 000 to support the cause.
                    </P>
                  </li>
                </ul>
              </li>

              <li>
                <ul class="uk-subnav uk-subnav-pill" uk-switcher>
                  <li><a href="#">HAND HOLDING TO END DROPOUTS</a></li>
                </ul>

                <ul class="uk-switcher uk-margin">
                  <li>
                    <p>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their school education and
                      not being able to secure a college education.
                    </p>
                    <p>
                      ◆ Amoha aims to hand hold and lift students from such
                      schools and enable them to move ahead into the next stage
                      of their life and career by appointing teachers and
                      bridging the gap.
                    </p>
                    <div class="uk-margin-top uk-overflow-auto">
                      <table class="uk-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src="assets/images/children.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/teacher.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/classroom.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/subject.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Children Served</td>
                            <td>Teachers Appointed</td>
                            <td>Classes Supported</td>
                            <td>Subjects Taught</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Valparai</td>
                            <td>228</td>
                            <td>2</td>
                            <td>5</td>
                            <td>3</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Sholayar Dam
                            </td>
                            <td>162</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </li>

              <!-- 2020-2021 start -->
              <li>
                <ul class="uk-subnav uk-subnav-pill" uk-switcher>
                  <li><a href="#">HAND HOLDING TO END DROPOUTS</a></li>
                  <li><a href="#">DIRECT EDUCATION SUPPORT</a></li>
                  <li><a href="#">PRACTICAL EDUCATION SUPPORT</a></li>
                </ul>

                <ul class="uk-switcher uk-margin">
                  <li>
                    <p>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their school education and
                      not being able to secure a college education.
                    </p>
                    <p>
                      ◆ Amoha aims to hand hold and lift students from such
                      schools and enable them to move ahead into the next stage
                      of their life and career by appointing teachers and
                      bridging the gap.
                    </p>
                    <div class="uk-margin-top uk-overflow-auto">
                      <table class="uk-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src="assets/images/children.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/teacher.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/classroom.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/subject.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Children Served</td>
                            <td>Teachers Appointed</td>
                            <td>Classes Supported</td>
                            <td>Subjects Taught</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Valparai</td>
                            <td>220</td>
                            <td>2</td>
                            <td>5</td>
                            <td>3</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Sholayar Dam
                            </td>
                            <td>150</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Vagarampalayam
                            </td>
                            <td>100</td>
                            <td>1</td>
                            <td>2</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Muthugondanur
                            </td>
                            <td>145</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Yercaud</td>
                            <td>85</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <li>
                    The trust has donated 100 calculators to Govt. School
                    Muthugondanur.
                  </li>
                  <li>
                    <p>
                      ◆ A few schools located in non-urban areas of the state
                      lack science labs and the equipment to teach children
                      practical science that is a part of their 10th, 11th and
                      12th grade education.
                    </p>
                    <p>
                      ◆ Amoha has provided the much needed lab equipment,
                      chemicals, charts, etc., to equip physics chemistry botany
                      and zoology labs of these schools. This has enormously
                      helped the students in securing better grades in the board
                      exams paving the way to their college education.
                    </p>
                    <!-- practical education support start -->
                    <div class="uk-overflow-auto uk-margin-top">
                      <table class="uk-table uk-table-divider">
                        <thead>
                          <tr style="height: 50px">
                            <th rowspan="2">School Name</th>
                            <th rowspan="2">Location</th>
                            <th rowspan="2">Lab Type</th>
                            <th rowspan="2">Amount Required</th>
                            <th colspan="3">Beneficiaries</th>
                            <!-- <th rowspan="2">Total</th>
                            <th rowspan="2">Total Amount to the School</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <!-- col 01 start -->
                          <tr style="height: 50px">
                            <td valign="bottom" rowspan="3">Govt. School</td>
                            <td valign="bottom" rowspan="3">Muthugondanur</td>
                            <td valign="bottom">Chemistry</td>
                            <td valign="bottom">9470</td>
                            <td valign="bottom" rowspan="3">245</td>
                            <!-- <td valign="bottom" rowspan="2">123</td>
                            <td valign="bottom" rowspan="2">140</td>
                            <td valign="bottom" rowspan="2">358</td>
                            <td valign="bottom" rowspan="2">28,008</td> -->
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Physics</td>
                            <td valign="bottom">28757</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Biology</td>
                            <td valign="bottom">6179</td>
                          </tr>
                          <!-- col 01 end -->
                          <!-- col 02 start -->
                          <tr style="height: 50px">
                            <td valign="bottom" rowspan="2">
                              Govt. School (Boys)
                            </td>
                            <td valign="bottom" rowspan="2">Thondamuthur</td>
                            <td valign="bottom">Physics</td>
                            <td valign="bottom">12744</td>
                            <td valign="bottom" rowspan="2">138</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Biology</td>
                            <td valign="bottom">8076</td>
                          </tr>
                          <!-- col 02 end -->
                          <!-- col 03 start -->
                          <tr style="height: 50px">
                            <td valign="bottom" rowspan="2">
                              Govt. School (Girls)
                            </td>
                            <td valign="bottom" rowspan="2">Thondamuthur</td>
                            <td valign="bottom">Chemistry</td>
                            <td valign="bottom">8797</td>
                            <td valign="bottom" rowspan="2">98</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Botany</td>
                            <td valign="bottom">1415</td>
                          </tr>
                          <!-- col 03 end -->
                        </tbody>
                      </table>
                    </div>
                    <!-- practical education support end -->
                  </li>
                </ul>
              </li>
              <!-- 2020-2021 end -->

              <!-- 2021-2022 start -->
              <li>
                <ul class="uk-subnav uk-subnav-pill" uk-switcher>
                  <li><a href="#">HAND HOLDING TO END DROPOUTS</a></li>
                  <li><a href="#">DIRECT EDUCATION SUPPORT</a></li>
                  <li><a href="#">PRACTICAL EDUCATION SUPPORT</a></li>
                </ul>

                <ul class="uk-switcher uk-margin">
                  <!-- hand holding to end dropouts start-->
                  <li>
                    <p>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their school education and
                      not being able to secure a college education.
                    </p>
                    <p>
                      ◆ Amoha aims to hand hold and lift students from such
                      schools and enable them to move ahead into the next stage
                      of their life and career by appointing teachers and
                      bridging the gap.
                    </p>
                    <div class="uk-margin-top uk-overflow-auto">
                      <table class="uk-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src="assets/images/children.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/teacher.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/classroom.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/subject.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Children Served</td>
                            <td>Teachers Appointed</td>
                            <td>Classes Supported</td>
                            <td>Subjects Taught</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Sholayar Dam
                            </td>
                            <td>121</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Valparai (Girls)
                            </td>
                            <td>201</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Vagarampalayam
                            </td>
                            <td>126</td>
                            <td>2</td>
                            <td>2</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Muthugondanur
                            </td>
                            <td>78</td>
                            <td>1</td>
                            <td>2</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Thondamuthur (Girls)
                            </td>
                            <td>134</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Yercaud</td>
                            <td>62</td>
                            <td>1</td>
                            <td>2</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Kinathukadavu
                            </td>
                            <td>143</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <!-- hand holding to end dropouts end-->

                  <li>
                    The trust has supported 10 students for NEET exam fees,
                    spending RS 15,000 for the cause.
                  </li>

                  <!-- practical education start -->
                  <li>
                    <p>
                      ◆ A few schools located in non-urban areas of the state
                      lack science labs and the equipment to teach children
                      practical science that is a part of their 10th, 11th and
                      12th grade education.
                    </p>
                    <p>
                      ◆ Amoha has provided the much needed lab equipment,
                      chemicals, charts, etc., to equip physics chemistry botany
                      and zoology labs of these schools. This has enormously
                      helped the students in securing better grades in the board
                      exams paving the way to their college education.
                    </p>
                    <!-- practical education support start -->
                    <div class="uk-overflow-auto uk-margin-top">
                      <table class="uk-table uk-table-divider">
                        <thead>
                          <tr style="height: 50px">
                            <th rowspan="2">School Name</th>
                            <!-- <th rowspan="2">Location</th>
                            <th rowspan="2">Lab Type</th> -->
                            <th rowspan="2">Amount Required</th>
                            <!-- <th colspan="3">Beneficiaries</th> -->
                            <!-- <th rowspan="2">Total</th>
                            <th rowspan="2">Total Amount to the School</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="height: 50px">
                            <td valign="bottom">
                              Govt. School Thondamuthur (Boys)
                            </td>
                            <td valign="bottom">29400</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">
                              Govt. School Thondamuthur (Girls)
                            </td>
                            <td valign="bottom">23900</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Govt. School Sholayar Dam</td>
                            <td valign="bottom">13600</td>
                          </tr>
                          <tr style="height: 50px">
                            <td valign="bottom">Govt. School Kalangal</td>
                            <td valign="bottom">39650</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- practical education support end -->
                  </li>
                  <!-- practical education end -->
                </ul>
              </li>
              <!-- 2021-2022 end -->

              <!-- 2022-2023 start -->
              <li>
                <ul class="uk-subnav uk-subnav-pill" uk-switcher>
                  <li><a href="#">HAND HOLDING TO END DROPOUTS</a></li>
                </ul>

                <ul class="uk-switcher uk-margin">
                  <!-- hand holding to end dropouts start-->
                  <li>
                    <p>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their school education and
                      not being able to secure a college education.
                    </p>
                    <p>
                      ◆ Amoha aims to hand hold and lift students from such
                      schools and enable them to move ahead into the next stage
                      of their life and career by appointing teachers and
                      bridging the gap.
                    </p>
                    <div class="uk-margin-top uk-overflow-auto">
                      <table class="uk-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src="assets/images/children.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/teacher.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/classroom.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/subject.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Children Served</td>
                            <td>Teachers Appointed</td>
                            <td>Classes Supported</td>
                            <td>Subjects Taught</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Sholayar Dam
                            </td>
                            <td>182</td>
                            <td>2</td>
                            <td>3</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Valparai (Girls)
                            </td>
                            <td>337</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Vagarampalayam
                            </td>
                            <td>178</td>
                            <td>2</td>
                            <td>2</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Thondamuthur (Girls)
                            </td>
                            <td>220</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Thondamuthur (Boys)
                            </td>
                            <td>304</td>
                            <td>2</td>
                            <td>5</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Kinathukadavu
                            </td>
                            <td>147</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Yercaud (Chinnamarudur)
                            </td>
                            <td>68</td>
                            <td>1</td>
                            <td>2</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Yercaud (Maramangalam)
                            </td>
                            <td>105</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Yercaud (Muluvi)
                            </td>
                            <td>37</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Seripalayam
                            </td>
                            <td>120</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Chinnathadagam
                            </td>
                            <td>68</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Parali</td>
                            <td>58</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <!-- hand holding to end dropouts end-->

                  <!-- practical education end -->
                </ul>
              </li>
              <!-- 2022-2023 end -->

              <!-- 2023-2024 start -->
              <li>
                <ul class="uk-subnav uk-subnav-pill" uk-switcher>
                  <li><a href="#">HAND HOLDING TO END DROPOUTS</a></li>
                </ul>

                <ul class="uk-switcher uk-margin">
                  <!-- hand holding to end dropouts start-->
                  <li>
                    <p>
                      ◆ A few schools located in remote corners of Tamil Nadu
                      lack adequate teaching staff leading to students in the
                      10th and 12th standards either dropping out of school or
                      failing in the board exams. This leads to these
                      disadvantaged students ending their school education and
                      not being able to secure a college education.
                    </p>
                    <p>
                      ◆ Amoha aims to hand hold and lift students from such
                      schools and enable them to move ahead into the next stage
                      of their life and career by appointing teachers and
                      bridging the gap.
                    </p>
                    <div class="uk-margin-top uk-overflow-auto">
                      <table class="uk-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <img
                                src="assets/images/children.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/teacher.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/classroom.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                            <th>
                              <img
                                src="assets/images/subject.png"
                                width="60"
                                height="60"
                                alt=""
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>Children Served</td>
                            <td>Teachers Appointed</td>
                            <td>Classes Supported</td>
                            <td>Subjects Taught</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Sholayar Dam
                            </td>
                            <td>148</td>
                            <td>2</td>
                            <td>3</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Valparai (Girls)
                            </td>
                            <td>288</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Vagarampalayam
                            </td>
                            <td>174</td>
                            <td>2</td>
                            <td>2</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Thondamuthur (Girls)
                            </td>
                            <td>201</td>
                            <td>2</td>
                            <td>4</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Thondamuthur (Boys)
                            </td>
                            <td>318</td>
                            <td>2</td>
                            <td>5</td>
                            <td>2</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Kinathukadavu
                            </td>
                            <td>161</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Yercaud (Chinnamarudur)
                            </td>
                            <td>51</td>
                            <td>1</td>
                            <td>2</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Yercaud (Maramangalam)
                            </td>
                            <td>117</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Yercaud (Muluvi)
                            </td>
                            <td>35</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Seripalayam
                            </td>
                            <td>120</td>
                            <td>1</td>
                            <td>3</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">
                              Govt. School Chinnathadagam
                            </td>
                            <td>65</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                          <tr>
                            <td class="uk-text-bold">Govt. School Parali</td>
                            <td>58</td>
                            <td>1</td>
                            <td>1</td>
                            <td>1</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </li>
                  <!-- hand holding to end dropouts end-->

                  <!-- practical education end -->
                </ul>
              </li>
              <!-- 2023-2024 end -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
