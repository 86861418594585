import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projectpage',
  templateUrl: './projectpage.component.html',
  styleUrls: ['./projectpage.component.scss']
})
export class ProjectpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
