<div class="uk-section-default">
    <div class="uk-section  uk-section-large uk-background-cover" style="background-image: url(assets/images/about-header.jpg);top: 0px;">
        <div class="uk-container">
        </div>
    </div>
</div>
<div class="uk-container uk-font-ubuntu">
    <div class="uk-card uk-card-default uk-card-body uk-border-rounded">
        <p>
            Amoha Global Trust is a government of India recognized charitable trust. All donations to the Amoha Global trust are eligible for tax-deduction under Section 80G of the Income Tax Act. You can download the 80G certificate from <span uk-lightbox><a class="" href="assets/images/80G.jpg">here.</a></span>
        </p>
        <div class="">
            <div class="uk-flex uk-flex-row uk-flex-center">
                <div class="uk-margin-large-top">
                    <h4>NEFT Transfers</h4>
                    <hr class="uk-divider-small">
                    <p class="uk-margin-remove uk-text-emphasis">Amoha Global Trust</p>
                    <p class="uk-margin-remove uk-text-emphasis">Kotak Mahindra Bank Ltd.</p>
                    <p class="uk-margin-remove uk-text-emphasis">Acc. No: 0411827753</p>
                    <p class="uk-margin-remove uk-text-emphasis">IFSC: KKBK0000491</p>
                </div>
                <div class="">
                    <img src="assets/images/donate.gif" width="250" height="250" alt="donate">
                </div>
            </div>
        </div>
    </div>
</div>