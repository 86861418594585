<!-- Desktop Navbar -->
<nav class="uk-navbar-container uk-visible@s" uk-navbar>
    <div class="uk-navbar-left">

        <a class="uk-navbar-item uk-logo" routerLink="/">
            <img src="assets/images/logo-full-1.png" width="150" height="80" alt="logo">
        </a>
        <div class="uk-flex uk-flex-row uk-margin-top">
            <p class="custom-freedom">Freedom</p><br>
            <p class="custom-ignorance">From Ignorance</p>
        </div>
    </div>
    <div class="uk-navbar-right uk-margin-large-right">

        <ul class="uk-navbar-nav">
            <li class=""><a class="uk-active uk-text-bold" routerLink="/">HOME</a></li>
            <li>
                <a class=" uk-text-bold" routerLink="cause">CAUSE</a>
            </li>
            <li><a class=" uk-text-bold" routerLink="team">TEAM</a></li>
            <li><a class=" uk-text-bold" routerLink="projects">PROJECTS</a></li>
            <li><a class=" uk-text-bold" routerLink="contribute">CONTRIBUTE</a></li>
            <li><a class=" uk-text-bold" routerLink="contactus">GET IN TOUCH</a></li>
        </ul>

    </div>

</nav>
<!-- Mobile Navbar -->
<nav class="uk-navbar uk-navbar-container uk-hidden@s">
    <div class="uk-navbar-left">
        <a class="uk-navbar-toggle" uk-toggle="target: #offcanvas-flip">
            <span uk-navbar-toggle-icon></span> <span class="uk-margin-small-left">Menu</span>
        </a>
    </div>
</nav>

<div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">

        <button class="uk-offcanvas-close" type="button" uk-close></button>

        <ul class="uk-list uk-margin-large-top">
            <li class=""><a class="uk-active uk-text-bold" routerLink="/">HOME</a></li>
            <li>
                <a class=" uk-text-bold" routerLink="cause">CAUSE</a>
            </li>
            <li><a class=" uk-text-bold" routerLink="team">TEAM</a></li>
            <li><a class=" uk-text-bold" routerLink="projects">PROJECTS</a></li>
            <li><a class=" uk-text-bold" routerLink="contribute">CONTRIBUTE</a></li>
            <li><a class=" uk-text-bold" routerLink="contactus">GET IN TOUCH</a></li>
        </ul>
    </div>
</div>